<template>
  <div style="text-align: left">课程列表</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="课程分类" size="small" style="margin-left: 20px">
      <el-cascader
        v-model="seachForm.categoryId"
        :props="props"
        :show-all-levels="false"
        :options="categoryOption"
      ></el-cascader>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <div style="display: flex; justify-content: flex-start">
    <el-button type="primary" size="mini" @click="addgood">创建课程</el-button>
  </div>
  <el-table
    :data="tableData"
    @expand-change="expanditem"
    row-key="id"
    :expand-row-keys="expandarr"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column type="expand">
      <template #default="props">
        <el-button type="primary" size="mini" @click="addvideo(props)"
          >添加视频</el-button
        >
        <el-table
          v-if="props.row.videosList && props.row.videosList.length > 0"
          :data="props.row.videosList"
          style="width: 100%"
        >
          <el-table-column prop="title" label="标题" width="180">
          </el-table-column>
          <el-table-column prop="content" label="简介" width="180">
          </el-table-column>
          <el-table-column label="视频链接" width="180">
            <template #default="scope">
              <a
                :href="videoBaseUrl + scope.row.videoUrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <el-tag>点击查看</el-tag>
              </a>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="创建日期">
          </el-table-column>
          <el-table-column prop="createDate" label="操作">
            <template #default="scope">
              <el-button type="text" @click="editvideo(scope.row, props)"
                >编辑</el-button
              >
              <el-button type="text" @click="deletevideo(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-table-column>
    <el-table-column prop="title" label="课程名称" width="180">
    </el-table-column>
    <el-table-column prop="liveUrl" label="直播上架地址" width="180">
    </el-table-column>
    <el-table-column prop="imgUrl" label="课程封面" width="180">
      <template #default="scope">
        <img
          v-if="scope.row.pictureUrl1"
          :src="imgBaseUrl + scope.row.pictureUrl1"
          alt=""
          style="width: 80px; height: 120px; object-fit: contain"
        />
      </template>
    </el-table-column>
    <el-table-column prop="categoryId" label="课程分类" width="180">
      <template #default="scope">
        <span>{{ findname(scope.row.categoryId) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="专家" width="180">
      <template #default="scope">
        <el-tag type="success" style="margin-left: 10px"  v-if="scope.row.expertNames[0]">{{
          scope.row.expertNames[0]
        }}</el-tag>
        <el-tag
          type="success"
          style="margin-left: 10px; margin-top: 10px"
          v-if="scope.row.expertNames[1]"
          >{{ scope.row.expertNames[1] }}</el-tag
        >
        <el-tag
          type="success"
          style="margin-left: 10px; margin-top: 10px"
          v-if="scope.row.expertNames[2]"
          >{{ scope.row.expertNames[2] }}</el-tag
        >
        <el-tag
          type="success"
          style="margin-left: 10px; margin-top: 10px"
          v-if="scope.row.expertNames.length > 3"
          >...</el-tag
        >
      </template>
    </el-table-column>
    <el-table-column prop="courseClass" label="课程价格(划线/售价)" width="180">
      <template #default="scope">
        <span>{{ scope.row.basePrice + "/" + scope.row.salePrice }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="showDate" label="发布时间"> </el-table-column>
    <el-table-column prop="topped" label="是否推荐">
         <template #default="scope">
        <el-tag type="success" v-if="scope.row.topped">推荐</el-tag>
        <!-- <el-tag type="success" v-if="scope.row.topped">推荐</el-tag> -->
      </template>
    </el-table-column>
    <el-table-column prop="goodStatus" label="操作">
      <template #default="scope">
        <el-button @click="recmdcourse(scope.row)" type="text" size="mini"
          >推荐</el-button
        >
        <el-button @click="editcourse(scope.row)" type="text" size="mini"
          >编辑</el-button
        >
        <el-button @click="deletecourse(scope.row)" type="text" size="mini"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页组件 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 20, 30]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
  <!-- 对话框 -->
  <el-dialog title="视频信息" v-model="dialogVisible" width="500px">
    <el-form ref="form" :model="videoForm" label-width="80px">
      <el-form-item label="视频标题" size="small">
        <el-input
          v-model="videoForm.title"
          clearable
          placeholder="请添加视频标题！"
        ></el-input>
      </el-form-item>
      <el-form-item label="视频简介" size="small">
        <el-input
          v-model="videoForm.content"
          clearable
          placeholder="请添加视频简介！"
        ></el-input>
      </el-form-item>
      <el-form-item label="视频上传" size="small">
        <el-upload
          :action="baseUrl + '/nktdec/uploader/uploadfile'"
          style="
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: start;
          "
          :limit="1"
          :auto-upload="false"
          accept=".mp4"
          :on-change="filechange"
          :file-list="videoFileList"
          :on-remove="handremove"
        >
          <template #default>
            <!-- <i class="el-icon-plus"></i> -->
            <el-button type="primary" size="mini">点击上传</el-button>
          </template>
          <template #tip>
            <!-- <div class="el-upload__tip">
              只能上传 jpg/png 文件，且不超过 500kb
            </div> -->
          </template>
          <!-- <template #file="{ file }">
            <div style="width: 100%; height: 100%">
              <video
                style="width: 100%; height: 100%"
                v-if="file.raw && file.raw.type === 'video/mp4'"
                :src="file.url"
              ></video>

              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </template> -->
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitvideo"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
// import _ from 'lodash'
export default {
  data() {
    return {
      tableData: [],
      seachForm: {
        categoryId: [],
      },
      key: Math.random(),
      categoryOption: [],
      professionOption: [],
      baseUrl: '',
      videoBaseUrl: '',
      dialogVisible: false,
      courseId: '',
      videoForm: {
        title: '',
        content: '',
        videoUrl: '',
        classId: '',
      },
      videoFileList: [],
      filechangeList: [],
      formurl: [],
      isaddvideo: true,
      vidTableData: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      props: {
        value: 'id',
        label: 'name'
      },
      imgBaseUrl: '',
      iserror: false,
      expandarr: []
    }
  },
  methods: {
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/classinfo/classinfos/1' + '?pageSize=' + val + (this.seachForm.categoryId === '' ? '' : '&categoryId=' + this.seachForm.categoryId))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?pageSize=' + this.pageSize + (this.seachForm.categoryId === '' ? '' : '&categoryId=' + this.seachForm.categoryId)
      const res = await this.$http.get('/nktdec/classinfo/classinfos/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async getcategory() {
      const res = await this.$http.get('/nktdec/categoryinfo/categoryinfos?type=CLASS')
      this.categoryOption = res.data
      this.categoryOption.forEach(item => {
        if (item.children.length === 0) {
          this.iserror = true
        }
        item.children.forEach(itemitem => delete itemitem.children)
      })
      if (this.iserror) {
        this.$message.error('请确保课程分类均为二级！')
        this.iserror = false
      }
      console.log(this.categoryOption)
    },
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?' + (this.seachForm.categoryId[1].trim() === '' ? '' : 'categoryId=' + this.seachForm.categoryId[1])
      console.log(str)
      const res = await this.$http.get('/nktdec/classinfo/classinfos/1' + str)
      console.log(res.data)
      res.data.content.forEach(async (item) => {
        item.videosList = []
        const result = await this.$http.get('/nktdec/classinfo/classinfo/' + item.id)
        console.log(res.data.videoInfos)
        item.videosList = result.data.videoInfos
      })
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async getprofession() {
      const res = await this.$http.get('/nktdec/expertinfo/expertinfos/1')
      this.professionOption = res.data.content
      console.log(this.professionOption)
    },
    async filechange(file) {
      console.log(file)
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('params', '参数')
      const res = await this.$http(
        {
          method: 'post',
          url: '/nktdec/uploader/uploadfile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data;',
          }
        }
      )
      console.log(res)
      this.videoFileList.push({
        name: file.name,
        url: this.baseUrl + res.data.result
      })
      this.formurl.push(res.data.result)
      console.log(this.videoFileList)
    },
    findname(id) {
      var arr = this.categoryOption.filter(item => {
        // console.log(item.children)
        // console.log(id)
        var i = item.children.findIndex(itemitem => itemitem.id === id)
        // console.log(i)
        if (i !== -1) {
          return item
        }
      })
      var j = arr[0].children.findIndex(item => item.id === id)
      // console.log(arr[0].children[j].name)
      return arr[0].children[j].name
    },
    async getcourseInfo(pageno) {
      const res = await this.$http.get('/nktdec/classinfo/classinfos/' + pageno)
      console.log(this.categoryOption)
      console.log(res)
      res.data.content.forEach(async (item) => {
        item.videosList = []
        const result = await this.$http.get('/nktdec/classinfo/classinfo/' + item.id)
        // console.log(res.data.videoInfos)
        item.videosList = result.data.videoInfos
      })
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      // this.getimgUrl()
      console.log(this.tableData)
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/classinfo/classinfos/1')
      console.log(res.data)
      res.data.content.forEach(async (item) => {
        item.videosList = []
        const result = await this.$http.get('/nktdec/classinfo/classinfo/' + item.id)
        console.log(res.data.videoInfos)
        item.videosList = result.data.videoInfos
      })
      this.seachForm.categoryId = []
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async changeshowstate(row) {
      console.log(row)
      row.adopted = !row.adopted
      const res = await this.$http.post('/nktdec/bannerinfo/bannerinfos', row)
      console.log(res)
      if (res.data.code === '1') this.$message.success('设置成功！')
      this.getcourseInfo(1)
    },
    editcourse(row) {
      console.log(row)
      window.sessionStorage.setItem('CourseInfo', JSON.stringify(row))
      this.$store.commit('setCourseInfo', row)
      this.$router.push('/addcourses/false')
    },
    async submitvideo() {
      console.log(this.videoForm)
      console.log(this.formurl)
      console.log(this.courseId)
      const data = {}
      if (this.isaddvideo) {
        data.classId = this.courseId
        data.title = this.videoForm.title
        data.content = this.videoForm.content
      } else {
        data.id = this.videoForm.id
        data.classId = this.courseId
        data.title = this.videoForm.title
        data.content = this.videoForm.content
      }
      // for (let i = 0; i < this.formurl.length; i++) {
        data.videoUrl = this.formurl[0] || this.videoForm.videoUrl
        console.log(data)
        const res = await this.$http.post('/nktdec/classinfo/videoinfos', data)
        console.log(res)
      // }
      this.dialogVisible = false
      // setTimeout(() => {
      // }, 0)
      // this.getcourseInfo(1)
    },
    addvideo(props) {
      console.log(props)
      this.courseId = props.row.id
      this.isaddvideo = true
      this.dialogVisible = true
      this.videoForm.title = ''
      this.videoForm.content = ''
      this.videoForm.videoUrl = ''
      this.videoForm.classId = ''
      this.videoFileList = []
    },
    async recmdcourse(row) {
      console.log(row)
      row.topped = !row.topped
      const res = await this.$http.post('/nktdec/classinfo/classinfos', {
        ...row
      })
      if (res.data.code === '1') {
        this.$message.success('修改成功！')
      }
      console.log(res)
    },
    editvideo(row, props) {
      console.log(row)
      console.log(props)
      this.courseId = props.row.id
      this.dialogVisible = true
      this.isaddvideo = false
      this.videoForm = row
      console.log(this.videoForm)
      this.videoFileList = []
      // if () {
      this.videoFileList.push({
        name: row.title,
        url: this.videoBaseUrl + row.videoUrl
      })
      // }
    },
    handremove(file) {
      console.log(file)
      console.log(this.videoFileList)
      var i = this.videoFileList.findIndex(item => item.url === file.url)
      this.videoFileList.splice(i, 1)
    },
    async expanditem(row, state) {
      console.log(row)
      if (this.expandarr.indexOf(row.id) === -1) {
         this.expandarr.push(row.id)
      } else {
        this.expandarr.splice(this.expandarr.indexOf(row.id), 1)
      }
    },
    async deletecourse(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该轮播, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/classinfo/classinfo/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.getcourseInfo(1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
    addgood() {
      this.$router.push('/addcourses/true')
    },
    async deletevideo(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该视频, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/classinfo/videoinfo/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.getcourseInfo(1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '课程管理',
        index: '/courselist'
      },
      {
        name: '课程列表',
        index: '/courselist'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.baseUrl = this.$http.defaults.baseURL
    // this.imgBaseUrl = this.$http.defaults.baseURL + 'nktdec/uploader/images/'
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'

    // this.videoBaseUrl = this.$http.defaults.baseURL + 'nktdec/uploader/videos/'
    this.videoBaseUrl = 'http://nktdec.ctvideo.cn/videos/'

    this.getcategory()
    this.getprofession()
      .then(() => {
        this.getcourseInfo(1)
      })
  }
}
</script>
<style scoped>
.lineform {
  display: flex;
  justify-content: flex-start;
}
.expendform {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.expendform .el-form-item {
  width: 48%;
}
</style>
